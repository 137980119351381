import {Button} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './backButton.scss';
import {BackButtonProps} from './backButton.types';
import {FC, useEffect} from 'react';
import {useTelegram} from '../../../app/hooks/useTelegram';

const b = block('back-button');

const BackButton: FC<BackButtonProps> = ({onClick, show = true}) => {
    const {user, tg, initData} = useTelegram();
    // const {screen} = useAppSelector((store) => store.screensSlice);
    // const {isSuccess} = channelsApi.useInitQuery({initData});

    useEffect(() => {
        if (user) {
            show ? tg.BackButton.show() : tg.BackButton.hide();
        }
    }, [show]);

    useEffect(() => {
        user && tg.BackButton.onClick(onClick);

        return () => {
            user && tg.BackButton.offClick(onClick);
        };
    }, [onClick]);
    return (
        <>
            {!user && show && (
                <Button onClick={onClick} view="flat-info" size="m" className={b()}>
                    Назад
                </Button>
            )}
        </>
    );
};

export default BackButton;
