import {Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './channelsList.scss';
import {FC} from 'react';
import {ChannelsListProps} from './channelsList.types';
import {IChannel} from '../../../app/shared/types/channel.interface';
import {ChannelCard} from '../channelCard';

const b = block('channels-list');

export const ChannelsList: FC<ChannelsListProps> = ({data, title}) => {
    return (
        <div className={b()}>
            {title && <Text variant="subheader-1">{title}</Text>}
            <div className={b('container')}>
                {data.map((channel: IChannel) => (
                    <ChannelCard
                        title={channel.title}
                        id={channel.id}
                        username={channel.username}
                        image={channel.image}
                        type={channel.type}
                        channels={channel.channels}
                        key={channel.id}
                    />
                ))}
            </div>
        </div>
    );
};
