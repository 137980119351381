import {Button, Card, Checkbox, Icon, Popup, Spin, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './channelCard.scss';
import {EllipsisVertical, ListCheck, Pencil, Plus, TrashBin} from '@gravity-ui/icons';
import {FC, useEffect, useRef, useState} from 'react';
import {ChannelCardProps} from './channelCard.types';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {channelsSlice, setSelectedChannels} from '../../../app/store/slices/channelsSlice';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {screensSlice} from '../../../app/store/slices/screensSlice';
import {IChannel} from '../../../app/shared/types/channel.interface';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('channel-card');

export const ChannelCard: FC<IChannel> = ({title, id, image, type, channels, username}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const [isCardMenuOpen, setIsCardMenuOpen] = useState(false);
    const [isCardChecked, setIsCardChecked] = useState(false);
    const [groupTitle, setGroupTitle] = useState(title);
    const boxRef = useRef(null);
    const [deleteChannelQuery, {isLoading: isDeleteChannelLoading}] =
        publisherApi.useDeleteChannelMutation();
    const [deleteGroupQuery, {isLoading: isDeleteGroupLoading}] =
        publisherApi.useDeleteGroupMutation();

    const {channelButton, selectedChannels} = useAppSelector((state) => state.channelsSlice);
    const {
        setChannelButton,
        selectChannel,
        unselectChannel,
        setIsPopupGroupOpen,
        setSelectedGroup,
    } = channelsSlice.actions;
    const {setScreen} = screensSlice.actions;
    const dispatch = useAppDispatch();

    const handleCheckboxToggle = () => {
        if (!isCardChecked) {
            dispatch(selectChannel(id));
            setIsCardChecked(true);
        } else {
            dispatch(unselectChannel(id));
            setIsCardChecked(false);
        }
    };

    const handleButtonDelete = () => {
        setIsCardMenuOpen(false);
        type === 'channel'
            ? deleteChannelQuery({initData, channelId: id})
            : deleteGroupQuery({initData, groupId: id});
    };

    const handleSelectMultipleButton = () => {
        dispatch(selectChannel(id));
        setIsCardChecked(true);
        dispatch(setChannelButton('checkbox'));
        setIsCardMenuOpen(false);
    };

    const handleChangeGroupButton = () => {
        dispatch(setSelectedGroup({id, title, type, image, channels}));
        dispatch(setScreen('channelsGroup'));
    };

    useEffect(() => {
        channelButton === 'menu' && setIsCardChecked(false);
    }, [channelButton]);

    useEffect(() => {
        selectedChannels.includes(id) && setIsCardChecked(true);
    }, [selectedChannels]);

    return (
        <div
            onClick={() =>
                channelButton === 'checkbox' && type === 'channel' && handleCheckboxToggle()
            }
            className={b()}
        >
            <Card view="raised" className={b('card')}>
                <div className={b('content-wrapper')}>
                    {type === 'channel' ? (
                        image ? (
                            <img
                                src={image ? image : ''}
                                alt="post image"
                                className={`${b('image')} ${b('image_channel')}`}
                            />
                        ) : (
                            <div className={`${b('image')} ${b('image_null')}`}>
                                <Text variant="subheader-3">{title?.slice(0, 1)}</Text>
                            </div>
                        )
                    ) : (
                        <div className={`${b('image')} ${b('image_group')}`}></div>
                    )}
                    <div className={b('text-container')}>
                        {type === 'channel' ? (
                            <>
                                <Text variant="subheader-1">
                                    {title && title.length < 17
                                        ? title
                                        : title?.slice(0, 17) + '...'}
                                </Text>
                                <Text variant="caption-2" color="hint">
                                    {/* '@username' | 'Приватный канал' */}
                                    {username ? '@' + username : locale['channel_card.private']}
                                </Text>
                            </>
                        ) : (
                            <>
                                <div className={b('title-wrapper')}>
                                    <Text variant="subheader-1">
                                        {title && title.length < 17
                                            ? title
                                            : title?.slice(0, 17) + '...'}
                                    </Text>{' '}
                                    {/* группа */}
                                    <Text variant="caption-2" color="hint">
                                        ({locale['channel_card.group']})
                                    </Text>
                                </div>
                                {/* Количество каналов в группе */}
                                <Text variant="caption-2" color="hint">
                                    {`${locale['channel_card.channels']} ${channels?.length || 0}`}
                                </Text>
                            </>
                        )}
                    </div>
                </div>
                {isDeleteChannelLoading || isDeleteGroupLoading ? (
                    <div className={b('button-wrapper')}>
                        <Spin />
                    </div>
                ) : (
                    <div
                        onClick={() =>
                            channelButton === 'menu'
                                ? setIsCardMenuOpen(!isCardMenuOpen)
                                : setIsCardChecked(!isCardChecked)
                        }
                        className={b('button-wrapper')}
                    >
                        {channelButton === 'checkbox' && type === 'channel' ? (
                            <Checkbox size="l" checked={isCardChecked} className={b('checkbox')} />
                        ) : (
                            <Button view="flat" size="m" className={b('button')} ref={boxRef}>
                                <Icon data={EllipsisVertical} size={16} />
                            </Button>
                        )}
                    </div>
                )}

                <Popup
                    open={isCardMenuOpen}
                    anchorRef={boxRef}
                    placement="left-end"
                    offset={[0, -20]}
                    onOutsideClick={() => setIsCardMenuOpen(false)}
                    className={b('popup')}
                >
                    <div className={b('popup-content')}>
                        {type === 'channel' ? (
                            <>
                                {/* Добавить в группу */}
                                <Button
                                    onClick={() => {
                                        dispatch(selectChannel(id));
                                        setIsCardMenuOpen(false);
                                        dispatch(setIsPopupGroupOpen(true));
                                    }}
                                    size="l"
                                    view="flat"
                                    className={b('button')}
                                >
                                    <div className={b('button-child')}>
                                        <Icon data={Plus} size={16} />
                                        {locale['channel_card.btn.add_to_group']}
                                    </div>
                                </Button>

                                {/* Удалить */}
                                <Button
                                    onClick={handleButtonDelete}
                                    size="l"
                                    view="flat-danger"
                                    className={b('button')}
                                >
                                    <div className={b('button-child')}>
                                        <Icon data={TrashBin} size={16} />
                                        {locale['channel_card.btn.delete']}
                                    </div>
                                </Button>
                            </>
                        ) : (
                            <>
                                {/* Изменить */}
                                <Button
                                    onClick={handleChangeGroupButton}
                                    size="l"
                                    view="flat"
                                    className={b('button')}
                                >
                                    <div className={b('button-child')}>
                                        <Icon data={Pencil} size={16} />
                                        {locale['channel_card.btn.change']}
                                    </div>
                                </Button>

                                {/* Удалить */}
                                <Button
                                    onClick={handleButtonDelete}
                                    size="l"
                                    view="flat-danger"
                                    className={b('button')}
                                >
                                    <div className={b('button-child')}>
                                        <Icon data={TrashBin} size={16} />
                                        {locale['channel_card.btn.delete']}
                                    </div>
                                </Button>
                            </>
                        )}
                    </div>
                </Popup>
            </Card>
        </div>
    );
};
