import {Button, Card, Icon, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './postCard.scss';
import {FC, useEffect, useState} from 'react';
import {UserLabel} from '../userLabel';
import {Post} from '../post/post';
import {Bell, BellSlash, Clock, LogoTelegram, Pencil, Pin, TrashBin} from '@gravity-ui/icons';
import {PostCardProps} from './postCard.types';
import {IChannel} from '../../../app/shared/types/channel.interface';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {Popup} from '../popup';
import {postSlice} from '../../../app/store/slices/postSlice';
import {screensSlice} from '../../../app/store/slices/screensSlice';
import {useAppDispatch} from '../../../app/hooks/redux';
import {MobileDatePicker} from '../mobileDatePicker/mobileDatePicker';
import {DateTimeFormatOptions} from '../../../app/shared/types/dateTimeFormatOptions.interface';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('post-card');

export const PostCard: FC<PostCardProps> = ({data}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const [showAllChannels, setShowAllChannels] = useState(false);
    const [isDeleteCardPopupOpen, setIsDeleteCardPopupOpen] = useState(false);
    const [isDeleteCardErrorPopupOpen, setIsDeleteCardErrorPopupOpen] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const {updateState} = postSlice.actions;
    const {setScreen} = screensSlice.actions;
    const dispatch = useAppDispatch();
    const [
        deletePost,
        {
            isLoading: isDeletePostLoading,
            isSuccess: isDeletePostSuccess,
            isError: isDeletePostError,
            error: deletePostErrorMessage,
        },
    ] = publisherApi.useDeletePostMutation();

    const [editPost, {isSuccess: isEditPostSuccess, isLoading: isEditPostLoading}] =
        publisherApi.useEditPostMutation();

    useEffect(() => {
        (isDeletePostSuccess || isDeletePostError) && setIsDeleteCardPopupOpen(false);
    }, [isDeletePostSuccess, isDeletePostError]);

    useEffect(() => {
        isDeletePostError && deletePostErrorMessage && setIsDeleteCardErrorPopupOpen(true);
    }, [isDeletePostError, deletePostErrorMessage]);

    const getPublicationStatus = () => {
        if (data.published) {
            // Опубликовано
            return locale['post_card.published'];
        } else {
            return data.delay ? locale['post_card.delayed'] : locale['post_card.in_progress']; // 'Ожидает публикации' | 'Публикуется...'
        }
    };

    const publicationStatus = getPublicationStatus();

    const channelsToString = () => {
        const arr: string[] = [];
        data.channels.forEach((channel: IChannel) => {
            if (channel.type === 'group') {
                channel.title &&
                    arr.push(channel.title + ` (${locale['post_card.channel_group']})`);
            } else {
                channel.title && arr.push(channel.title);
            }
        });
        const result = arr.join(', ');
        return result;
    };

    const channelsString = channelsToString();

    const getDate = () => {
        const options: DateTimeFormatOptions = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        };
        if (data.delay) {
            return new Date(data.delay * 1000).toLocaleDateString('ru-RU', options);
        } else if (data.published) {
            return new Date(data.published * 1000).toLocaleDateString('ru-RU', options);
        } else {
            return new Date(data.created_at * 1000).toLocaleDateString('ru-RU', options);
        }
    };

    const getTime = () => {
        const options: DateTimeFormatOptions = {
            hour: 'numeric',
            minute: 'numeric',
        };
        if (data.delay) {
            return new Date(data.delay * 1000).toLocaleTimeString('ru-RU', options);
        } else if (data.published) {
            return new Date(data.published * 1000).toLocaleTimeString('ru-RU', options);
        } else {
            return new Date(data.created_at * 1000).toLocaleTimeString('ru-RU', options);
        }
    };

    const date = getDate();
    const time = getTime();

    // Обработчик кнопки "Редактировать"
    const handleEditButtonClick = () => {
        dispatch(updateState(data));
        dispatch(setScreen('postEditor'));
    };

    // Обработчик кнопки "Опубликовать сейчас"
    const handlePublishNowButton = () => {
        if (!isEditPostLoading) {
            const postData = {
                text: data.text,
                channels: data.channels.map((channel: IChannel) => {
                    return {id: channel.id, type: channel.type};
                }),
                pinned: data.pinned,
                notifiable: data.notifiable,
                delay: null,
                text_above: data.textAbove,
                disable_preview: data.disablePreview,
                buttons: data.buttons,
                media: data.media?.id,
            };

            editPost({initData, postData, postId: data.id});
        }
    };

    const handleDatePickerSelect = (date: any) => {
        const postData = {
            text: data.text,
            channels: data.channels.map((channel: IChannel) => {
                return {id: channel.id, type: channel.type};
            }),
            pinned: data.pinned,
            notifiable: data.notifiable,
            delay: date,
            text_above: data.textAbove,
            disable_preview: data.disablePreview,
            buttons: data.buttons,
            media: data.media?.id,
        };

        editPost({initData, postData, postId: data.id});
    };

    return (
        <>
            <Card view="raised" className={b()}>
                <div className={b('header')}>
                    <UserLabel />
                    <div className={b('status-wrapper')}>
                        <Text
                            className={b('gradient-text')}
                            variant="caption-2"
                            color={data.published ? 'positive' : 'warning'}
                        >
                            {publicationStatus}
                        </Text>
                        <Text variant="caption-2" color="hint">
                            {`${date} ${locale['post_card.published_at']} ${time}`}
                        </Text>
                    </div>
                </div>
                <Post
                    text={data.text}
                    pinned={data.pinned}
                    buttons={data.buttons}
                    time={time}
                    media={data.media}
                    channels={data.channels}
                    textAbove={data.textAbove}
                />
                <div className={b('info')}>
                    <div className={b('channels')}>
                        <Icon data={LogoTelegram} size={16} />

                        <Text variant="caption-2" color="secondary" className={b('channels-text')}>
                            {channelsString.length < 32 || showAllChannels
                                ? channelsString
                                : channelsString.slice(0, 30) + '...'}
                        </Text>
                        {channelsString.length >= 32 && !showAllChannels && (
                            <div
                                onClick={() => setShowAllChannels(true)}
                                className={b('channels-button')}
                            >
                                {/* смотреть все */}
                                <Text variant="caption-2">{locale['post_card.btn.show_all']}</Text>
                            </div>
                        )}
                    </div>
                    <div className={b('info-icons')}>
                        {data.pinned && <Icon data={Pin} size={16} />}
                        {data.notifiable ? (
                            <Icon data={Bell} size={16} />
                        ) : (
                            <Icon data={BellSlash} size={16} />
                        )}
                    </div>
                </div>
                <div className={b('button-container')}>
                    {data.editable && (
                        <div className={b('button-inline-wrapper')}>
                            {/* Редактировать */}
                            <Button
                                onClick={handleEditButtonClick}
                                size="xl"
                                width="max"
                                view="outlined"
                                className={b('button-inline-item')}
                            >
                                <div className={b('button-child')}>
                                    <Icon data={Pencil} size={20} />
                                    {locale['post_card.btn.edit']}
                                </div>
                            </Button>

                            {/* Время */}
                            {!data.published && (
                                <Button
                                    onClick={() => setIsDatePickerOpen(true)}
                                    size="xl"
                                    width="max"
                                    view="outlined"
                                    className={b('button-inline-item')}
                                >
                                    <div className={b('button-child')}>
                                        <Icon data={Clock} size={20} />
                                        {locale['post_card.btn.time']}
                                    </div>
                                </Button>
                            )}
                        </div>
                    )}

                    {/* Опубликовать сейчас */}
                    {!data.published && data.editable && (
                        <Button
                            onClick={handlePublishNowButton}
                            size="xl"
                            width="max"
                            view="outlined-info"
                        >
                            <div className={b('button-child')}>
                                <Icon data={LogoTelegram} size={20} />
                                {locale['post_card.btn.publish_now']}
                            </div>
                        </Button>
                    )}

                    {/* Удалить */}
                    {data.deletable && (
                        <Button
                            onClick={() => setIsDeleteCardPopupOpen(true)}
                            size="xl"
                            width="max"
                            view="outlined-danger"
                        >
                            <div className={b('button-child')}>
                                <Icon data={TrashBin} size={20} />
                                {locale['post_card.btn.delete']}
                            </div>
                        </Button>
                    )}
                </div>

                {/* Попап удаления поста */}
                <Popup
                    title={locale['popup.delete_post.title']} // Удалить пост?
                    open={isDeleteCardPopupOpen}
                    onClose={() => setIsDeleteCardPopupOpen(false)}
                    buttonText={
                        isDeletePostLoading
                            ? locale['popup.btn.delete.loading']
                            : locale['popup.btn.delete']
                    } // 'Удаление...' | 'Удалить'
                    buttonView="outlined-danger"
                    onButtonClick={() =>
                        !isDeletePostLoading && deletePost({initData, postId: data.id})
                    }
                    buttonTwoText={locale['popup.btn.cancel']} // Отмена
                    buttonTwoView="outlined"
                >
                    {/* Отменить это действие будет невозможно. */}
                    <Text>{locale['popup.delete_post.text']}</Text>
                </Popup>

                {/* Попап с ошибкой удаления поста */}
                <Popup
                    title={locale['popup.delete_post_error.title']} // Не удалось удалить пост
                    open={isDeleteCardErrorPopupOpen}
                    onClose={() => setIsDeleteCardErrorPopupOpen(false)}
                    buttonTwoText={locale['popup.btn.ok']} // Ок
                >
                    <Text>{deletePostErrorMessage ? deletePostErrorMessage.toString() : ''}</Text>
                </Popup>

                {/* Датапикер */}
                <MobileDatePicker
                    isOpen={isDatePickerOpen}
                    onSelect={handleDatePickerSelect}
                    onCancel={() => setIsDatePickerOpen(false)}
                    delay={data.delay}
                />
            </Card>
        </>
    );
};
