import {Modal} from '@gravity-ui/uikit';
import {FC, useEffect} from 'react';
import block from 'bem-cn-lite';
import {ModalWithMediaProps} from './modalWithMedia.types';

import './modalWithMedia.scss';
import {useTelegram} from '../../../app/hooks/useTelegram';
const b = block('modal-with-media');

const ModalWithMedia: FC<ModalWithMediaProps> = ({isOpen, onClose, url, type, alt}) => {
    const {tg, user} = useTelegram();

    useEffect(() => {
        isOpen ? tg.MainButton.hide() : tg.MainButton.show();
    }, [isOpen]);

    return (
        <Modal open={isOpen} onClose={onClose} className={b()}>
            <div>
                {type === 'image' && <img src={url} alt={alt} className={b('media')} />}
                {type === 'video' && (
                    <video playsInline={true} autoPlay={true} controls className={b('media')}>
                        <source src={url} />
                        Формат не поддерживается
                    </video>
                )}
            </div>
        </Modal>
    );
};

export default ModalWithMedia;
