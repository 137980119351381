import {Icon, Switch, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './linkPreview.scss';
import {FC, useEffect, useState} from 'react';
import {LinkPreviewProps} from './linkPreview.typex';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {postSlice} from '../../../app/store/slices/postSlice';
import {Link} from '@gravity-ui/icons';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('link-preview');

const LinkPreview: FC<LinkPreviewProps> = ({url}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const [isChecked, setIsChecked] = useState(false);
    const {disablePreview} = useAppSelector((store) => store.postSlice);
    const {setDisablePreview} = postSlice.actions;
    const dispatch = useAppDispatch();

    useEffect(() => {
        disablePreview ? setIsChecked(false) : setIsChecked(true);
    }, [disablePreview]);
    return (
        <div className={b()}>
            <div className={b('link')}>
                <div className={b('link-icon')}>
                    <Icon data={Link} size={20} />
                </div>
                <div className={b('link-text-wrapper')}>
                    {/* Предпросмотр ссылки */}
                    <Text variant="subheader-1">{locale['editor.link.preview']}</Text>
                    <Text variant="caption-2" color="hint">
                        {url}
                    </Text>
                </div>
            </div>

            <Switch
                className={b('switch')}
                checked={isChecked}
                onChange={() => dispatch(setDisablePreview(!disablePreview))}
            />
        </div>
    );
};

export default LinkPreview;
