import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
const baseUrl = process.env.REACT_APP_API_URL;

const transformErrorResponse = (err: any) => {
    if (typeof err.data.errors === 'string') {
        return err.data.errors;
    } else {
        const errorMessages: string[] = [];
        err.data.errors.forEach((error: any) => {
            errorMessages.push(Object.values(error).toString());
        });

        return errorMessages.join('. ');
    }
};

export const publisherApi = createApi({
    tagTypes: ['USER', 'POSTS', 'LOCALIZATION'],
    reducerPath: 'publisherApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
    }),
    endpoints: (build) => ({
        init: build.query({
            query: ({initData}) => ({
                url: `${baseUrl}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }),
            providesTags: ['USER'],
        }),

        addChannel: build.mutation({
            query: ({initData, formData}) => ({
                url: `${baseUrl}/channel`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData,
            }),
            invalidatesTags: ['USER'],
            transformErrorResponse(err: any) {
                return transformErrorResponse(err);
            },
        }),

        deleteChannel: build.mutation({
            query: ({initData, channelId}) => ({
                url: `${baseUrl}/channel/${channelId}`,
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }),
            invalidatesTags: ['USER'],
        }),

        addGroup: build.mutation({
            query: ({initData, formData}) => ({
                url: `${baseUrl}/group`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData,
            }),
            invalidatesTags: ['USER'],
            transformErrorResponse(err: any) {
                if (typeof err.data.errors === 'string') {
                    return err.data.errors;
                } else {
                    return err.data.errors[0].title;
                }
            },
        }),

        deleteGroup: build.mutation({
            query: ({initData, groupId}) => ({
                url: `${baseUrl}/group/${groupId}`,
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }),
            invalidatesTags: ['USER'],
        }),

        editGroup: build.mutation({
            query: ({initData, groupId, formData}) => ({
                url: `${baseUrl}/group/${groupId}`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData,
            }),
            invalidatesTags: ['USER'],
            transformErrorResponse(err: any) {
                if (typeof err.data.errors === 'string') {
                    return err.data.errors;
                } else {
                    return err.data.errors[0].id;
                }
            },
        }),

        getPosts: build.query({
            query: ({initData, paginationData}) => ({
                url: `${baseUrl}/wall`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify(paginationData),
            }),

            providesTags: ['POSTS'],
        }),

        createPost: build.mutation({
            query: ({initData, postData}) => ({
                url: `${baseUrl}/post`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            }),
            invalidatesTags: ['POSTS'],
            transformErrorResponse: (err: any) => {
                if (typeof err.data.errors === 'string') {
                    return err.data.errors;
                } else {
                    return Object.values(err.data.errors).join('. ') + '.';
                }
            },
        }),

        editPost: build.mutation({
            query: ({initData, postData, postId}) => ({
                url: `${baseUrl}/post/${postId}`,
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            }),
            transformErrorResponse: (err: any) => {
                if (typeof err.data.errors === 'string') {
                    return err.data.errors;
                } else {
                    return Object.values(err.data.errors).join('. ') + '.';
                }
            },
            invalidatesTags: ['POSTS'],
        }),

        deletePost: build.mutation({
            query: ({initData, postId}) => ({
                url: `${baseUrl}/post/${postId}`,
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }),
            transformErrorResponse: (response: {status: number; data: any}) => {
                return response.data.errors;
            },
            invalidatesTags: (result) => {
                return result === undefined ? [] : ['POSTS'];
            },
        }),

        fileUpload: build.mutation({
            query: ({initData, data}) => ({
                url: `${baseUrl}/post/attach`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: data,
            }),
            transformErrorResponse: (res: any) => {
                if (typeof res.data.errors === 'string') {
                    return res.data.errors;
                } else {
                    return Object.values(res.data.errors).join('. ') + '.';
                }
            },
        }),

        getLocalization: build.query({
            query: ({initData}) => ({
                url: `${baseUrl}/localization`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }),
            providesTags: ['LOCALIZATION'],
        }),

        updateSettings: build.mutation({
            query: ({initData, settings}) => ({
                url: `${baseUrl}/settings`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${initData}`,
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify(settings),
            }),
        }),
    }),
});
