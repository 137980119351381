import {Card, Icon, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './userLabel.scss';
import {FC} from 'react';
import {CircleCheckFill, Person} from '@gravity-ui/icons';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('user-label');

interface UserLabelProps {
    className?: string;
}

export const UserLabel: FC<UserLabelProps> = ({className}) => {
    const {initData, user} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    return (
        <div className={`${b()} ${className ? className : ''}`}>
            {/* <img
                src={
                    user
                        ? user.photo_url
                        : 'https://images.unsplash.com/photo-1504593811423-6dd665756598?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                }
                className={b('image')}
            /> */}
            <div className={b('user-avatar')}>
                {!user && <Icon data={Person} />}
                {user && user.first_name && user.first_name[0].toUpperCase()}
                {user && user.last_name && user.last_name[0].toUpperCase()}
            </div>
            <div className={b('info')}>
                <div className={b('title-wrapper')}>
                    <Text variant="caption-2">
                        {user ? `${user.first_name} ${user.last_name}` : 'User Name'}
                    </Text>
                    <Text variant="caption-2" color="hint">
                        {/* вы */}
                        {locale['post_card.owner']}
                    </Text>
                    <div className={b('icon')}>
                        <Icon data={CircleCheckFill} />
                    </div>
                </div>
                <Text variant="caption-2" color="hint">
                    {user ? `@${user.username}` : '@username'}
                </Text>
            </div>
        </div>
    );
};
