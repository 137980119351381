const tg = window.Telegram.WebApp;

export const useTelegram = () => {
    const onClose = () => {
        tg.close();
    };

    const onToggleButton = () => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    };

    return {
        tg,
        initData: tg.initData,
        // initData:
        //     'user=%7B%22id%22%3A415060621%2C%22first_name%22%3A%22Alexandr%22%2C%22last_name%22%3A%22Filimonov%22%2C%22username%22%3A%22fil_rocks%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-7969125651988143600&chat_type=private&auth_date=1717660599&hash=1777ff7011af130543ca2f76953651593b6439a7005fce7f2c8186d295f04590',
        user: tg.initDataUnsafe?.user,
        userId: tg.initDataUnsafe?.user?.id,
        queryId: tg.initDataUnsafe?.query_id,
        platform: tg.platform,
        onClose,
        onToggleButton,
    };
};
