import {Button, Icon, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './channelsTopMenu.scss';
import {FC} from 'react';
import {ChannelsListProps} from './channelsTopMenu.types';
import {IChannel} from '../../../app/shared/types/channel.interface';
import {ChannelCard} from '../channelCard';
import {Plus, TrashBin, Xmark} from '@gravity-ui/icons';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {channelsSlice} from '../../../app/store/slices/channelsSlice';

const b = block('channels-top-menu');

export const ChannelsTopMenu: FC = () => {
    const {selectedChannels} = useAppSelector((store) => store.channelsSlice);
    const {setChannelButton, deleteSelectedChannels} = channelsSlice.actions;
    const dispatch = useAppDispatch();

    const handleCloseButtonClick = () => {
        dispatch(deleteSelectedChannels());
        dispatch(setChannelButton('menu'));
    };

    return (
        <div className={b()}>
            <div className={b('container')}>
                {/* <Button size="m" view="normal-contrast" className={b('button')}>
                    <div className={b('button-child')}>
                        <Icon data={Plus} size={16} />
                        Добавить в группу
                    </div>
                </Button> */}
                <Button size="m" view="normal-contrast" className={b('button')}>
                    <div className={`${b('button-child')} ${b('button-child_red')}`}>
                        <Icon data={TrashBin} size={16} />
                        {'Удалить' +
                            `${selectedChannels.length ? ' (' + selectedChannels.length + ')' : ''}`}
                    </div>
                </Button>
                <Button
                    onClick={handleCloseButtonClick}
                    size="m"
                    view="flat-contrast"
                    className={b('button')}
                >
                    <Icon data={Xmark} size={16} />
                </Button>
            </div>
        </div>
    );
};
