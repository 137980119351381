import {
    Bold,
    Check,
    EyeSlash,
    Italic,
    Link,
    QuoteClose,
    Strikethrough,
    Underline,
    Xmark,
} from '@gravity-ui/icons';
import {Button, Card, Icon, TextInput, useOutsideClick} from '@gravity-ui/uikit';
import {Editor} from '@tiptap/core';
import {BubbleMenu} from '@tiptap/react';

import './bubbleMenu.scss';
import block from 'bem-cn-lite';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {yupSchemaInputUrlForm} from '../../../../utils/validate';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import {useTelegram} from '../../../../app/hooks/useTelegram';
import {publisherApi} from '../../../../app/store/api/publisher/publisherApi';
const b = block('bubble-menu');

interface BubbleMenuProps {
    editor: Editor | null;
    platform?: string;
    openUrButtonRef?: any;
}

interface ButtonsProps {
    editor: Editor | null;
    isUrlPopupOpen: boolean;
    setIsUrlPopupOpen: (isOpen: boolean) => void;
    openUrButtonRef?: any;
}

const Buttons = ({editor, isUrlPopupOpen, setIsUrlPopupOpen, openUrButtonRef}: ButtonsProps) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const [url, setUrl] = useState('');
    const setLink = useCallback(
        (url: string) => {
            // cancelled
            if (url === null) {
                return;
            }

            // empty
            if (url === '') {
                editor?.chain().focus().extendMarkRange('link').unsetLink().run();
                setIsUrlPopupOpen(false);
                return;
            }

            const transformUrl =
                url.startsWith('http://') || url.startsWith('https://') ? url : 'https://' + url;

            // update link
            editor?.chain().focus().extendMarkRange('link').setLink({href: transformUrl}).run();
            setIsUrlPopupOpen(false);
            setUrl(transformUrl);
        },
        [editor],
    );

    const ref = useRef(null);

    useOutsideClick({
        ref: ref,
        handler: () => setIsUrlPopupOpen(false),
    });

    const {
        register,
        formState: {errors, isValid},
    } = useForm({
        mode: 'onSubmit',
        values: {url},
        resolver: yupResolver(yupSchemaInputUrlForm),
    });

    const handlerKeydown = (event: any) => {
        if (event.code === 'KeyU' && (event.ctrlKey || event.metaKey) && !event.shiftKey) {
            event.preventDefault();
            setIsUrlPopupOpen(true);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handlerKeydown);
        return () => document.removeEventListener('keydown', handlerKeydown);
    }, []);

    if (!editor) {
        return null;
    }

    return (
        <Card className={b('card')} size="l" view="raised" ref={ref}>
            {!isUrlPopupOpen ? (
                <>
                    <Button
                        selected={editor?.isActive('bold')}
                        onClick={() => editor?.chain().focus().toggleBold().run()}
                        size="m"
                        view="flat"
                        className={b('button')}
                    >
                        <Icon data={Bold} size={16} />
                    </Button>
                    <Button
                        selected={editor?.isActive('italic')}
                        onClick={() => editor?.chain().focus().toggleItalic().run()}
                        size="m"
                        view="flat"
                    >
                        <Icon data={Italic} size={16} />
                    </Button>
                    <Button
                        selected={editor?.isActive('underline')}
                        onClick={() => editor?.chain().focus().toggleUnderline().run()}
                        size="m"
                        view="flat"
                    >
                        <Icon data={Underline} size={16} />
                    </Button>
                    <Button
                        selected={editor?.isActive('strike')}
                        onClick={() => editor?.chain().focus().toggleStrike().run()}
                        size="m"
                        view="flat"
                    >
                        <Icon data={Strikethrough} size={16} />
                    </Button>
                    <Button
                        selected={editor?.isActive('blockquote')}
                        onClick={() => {
                            editor?.chain().focus().toggleBlockquote().run();
                        }}
                        size="m"
                        view="flat"
                    >
                        <Icon data={QuoteClose} size={16} />
                    </Button>
                    <Button
                        selected={editor?.isActive('highlight')}
                        onClick={() =>
                            editor?.chain().focus().toggleHighlight({color: '#cbcbcb48'}).run()
                        }
                        size="m"
                        view="flat"
                    >
                        <Icon data={EyeSlash} size={16} />
                    </Button>
                    <Button
                        selected={editor?.isActive('link')}
                        onClick={() => setIsUrlPopupOpen(true)}
                        size="m"
                        view="flat"
                    >
                        <Icon data={Link} size={16} />
                    </Button>
                </>
            ) : (
                <div className={b('link-wrapper')}>
                    <Button
                        onClick={() => {
                            setIsUrlPopupOpen(false);
                        }}
                        size="m"
                        view="flat"
                    >
                        <Icon data={Xmark} size={20} />
                    </Button>
                    <TextInput
                        placeholder={locale['editor.link.placeholder']} // Ссылка
                        validationState={errors.url?.message ? 'invalid' : undefined}
                        errorMessage={errors.url?.message}
                        autoFocus
                        view="clear"
                        className={b('link-input')}
                        {...register('url', {
                            onChange(e) {
                                setUrl(e.target.value);
                            },
                            value: url,
                        })}
                    />
                    <Button
                        disabled={!isValid}
                        onClick={() => {
                            setLink(url);
                        }}
                        view="flat-action"
                        size="m"
                        ref={openUrButtonRef}
                    >
                        <Icon data={Check} size={20} />
                    </Button>
                </div>
            )}
        </Card>
    );
};

export default ({editor, platform, openUrButtonRef}: BubbleMenuProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
    const [isUrlPopupOpen, setIsUrlPopupOpen] = useState(false);

    useOutsideClick({
        ref: ref,
        handler: isVisible && !editor?.isFocused ? () => setIsVisible(false) : () => {},
    });

    useEffect(() => {
        editor?.isFocused && setIsVisible(true);
    }, [editor?.isFocused]);

    const isKeyboardOpen = useDetectKeyboardOpen();

    return platform === 'ios' || platform === 'android' ? (
        <div className={`${b('wrapper')} ${!isKeyboardOpen ? b('hidden') : ''}`} ref={ref}>
            <Buttons
                editor={editor}
                isUrlPopupOpen={isUrlPopupOpen}
                setIsUrlPopupOpen={setIsUrlPopupOpen}
            />
        </div>
    ) : (
        <BubbleMenu editor={editor} tippyOptions={{duration: 100}}>
            <Buttons
                editor={editor}
                isUrlPopupOpen={isUrlPopupOpen}
                setIsUrlPopupOpen={setIsUrlPopupOpen}
                openUrButtonRef={openUrButtonRef}
            />
        </BubbleMenu>
    );
};
