import block from 'bem-cn-lite';
import './channels.scss';

import {Button, Icon, Select, Text, TextInput} from '@gravity-ui/uikit';
import {Plus} from '@gravity-ui/icons';
import {ChannelsList} from '../../ui/channelsList';
import {IChannel} from '../../../app/shared/types/channel.interface';
import {ChannelsTopMenu} from '../../ui/channelsTopMenu';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {Popup} from '../../ui/popup';
import {useEffect, useState} from 'react';
import TgButton from '../../ui/tgButton/tgButton';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {screensSlice} from '../../../app/store/slices/screensSlice';
import BackButton from '../../ui/backButton/backButton';
import {channelsSlice} from '../../../app/store/slices/channelsSlice';
import SearchInput from '../../ui/searchInput/searchInput';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('channels');

export const Channels = () => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});

    const {data} = publisherApi.useInitQuery({initData});

    const [isAddChannelPopupOpen, setIsAddChannelPopupOpen] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [addChannelPopupInputText, setAddChannelPopupInputText] = useState('');
    const [addChannelInGroupSelectId, setAddChannelInGroupSelectId] = useState('');
    const {selectedChannels, channelButton, isPopupGroupOpen} = useAppSelector(
        (store) => store.channelsSlice,
    );

    const [
        addChannelQuery,
        {
            isSuccess: isAddChannelQuerySuccess,
            error: addChannelError,
            isLoading: isAddChannelLoading,
        },
    ] = publisherApi.useAddChannelMutation();
    const [editGroupMutation, {isSuccess: isEditGroupSuccess, isLoading: isEditGroupLoading}] =
        publisherApi.useEditGroupMutation();
    const {setScreen} = screensSlice.actions;
    const {setIsPopupGroupOpen, deleteSelectedChannels} = channelsSlice.actions;
    const dispatch = useAppDispatch();
    const [addChannelErrorMessage, setAddChannelErrorMessage] = useState('');

    const addChannel = () => {
        if (addChannelPopupInputText) {
            const formData = new URLSearchParams({id: addChannelPopupInputText}).toString();
            addChannelQuery({initData, formData});
        } else {
            // Введите название канала
            setAddChannelErrorMessage(locale['popup.add_channel.error.name']);
        }
    };

    const addChannelsInGroup = () => {
        const formData = new URLSearchParams();
        const selectedGroup: IChannel[] = channelGroups.filter((group: IChannel) => {
            return group.id.toString() === addChannelInGroupSelectId.toString();
        });

        selectedGroup[0].channels?.forEach((channel: IChannel) =>
            formData.append('channels[]', channel.id.toString()),
        );

        selectedGroup[0].title && formData.append('title', selectedGroup[0].title);
        formData.append('channels[]', selectedChannels[0].toString());

        editGroupMutation({initData, groupId: addChannelInGroupSelectId, formData});
    };

    const searchFilteredData = data
        ? data.channels.filter((channel: IChannel) =>
              channel.title?.toLowerCase().startsWith(searchInputValue.toLowerCase()),
          )
        : [];

    const channels = searchFilteredData
        ? searchFilteredData.filter((channel: IChannel) => channel.type === 'channel')
        : [];
    const channelGroups = searchFilteredData
        ? searchFilteredData.filter((channel: IChannel) => channel.type === 'group')
        : [];

    useEffect(() => {
        if (isAddChannelPopupOpen && isAddChannelQuerySuccess) {
            setAddChannelPopupInputText('');
            setIsAddChannelPopupOpen(false);
        }
    }, [isAddChannelQuerySuccess]);

    useEffect(() => {
        if (isPopupGroupOpen && isEditGroupSuccess) {
            dispatch(setIsPopupGroupOpen(false));
        }

        isEditGroupSuccess && dispatch(deleteSelectedChannels());
    }, [isEditGroupSuccess]);

    useEffect(() => {
        addChannelError && setAddChannelErrorMessage(addChannelError.toString());
    }, [addChannelError]);

    useEffect(() => {
        if (data.length > 0) {
            setAddChannelInGroupSelectId(data[0].id);
        }
    }, [data]);

    return (
        <main className={b()}>
            <BackButton onClick={() => dispatch(setScreen('home'))} />
            {channelButton === 'checkbox' && <ChannelsTopMenu />}
            <div className={b('menu')}>
                {channelButton === 'menu' ? (
                    <>
                        <Text variant="body-1" color="hint">
                            {/* Ваши каналы */}
                            {locale['channels.counter.title']} - {channels.length}
                        </Text>
                        <Button
                            onClick={() => dispatch(setScreen('channelsGroup'))}
                            size="m"
                            view="action"
                        >
                            <div className={b('button-child')}>
                                <Icon data={Plus} size={16} />
                                {/* Создать группу */}
                                {locale['channels.btn.create_group']}
                            </div>
                        </Button>
                    </>
                ) : (
                    <>
                        {/* <Text variant="body-1" color="hint">
                            Выбранные каналы - {selectedChannels.length} из {channels.length}
                        </Text>
                        <Button size="m" view="outlined">
                            Выбрать все
                        </Button> */}
                    </>
                )}
            </div>
            {data && data.length > 0 && (
                <SearchInput
                    value={searchInputValue}
                    onChange={(e) => setSearchInputValue(e.target.value)}
                />
            )}

            {data && data.length === 0 && (
                <Text className={b('message')} color="secondary">
                    {/* У Вас ещё нет подключенных телеграм-каналов */}
                    {locale['channels.message.no_channels']}
                </Text>
            )}

            {/* Группы */}
            {channelGroups.length > 0 && (
                <ChannelsList data={channelGroups} title={locale['channels_list.groups']} />
            )}

            {/* Каналы */}
            {channels.length > 0 && (
                <ChannelsList data={channels} title={locale['channels_list.channels']} />
            )}
            <TgButton
                title={locale['main_button.connect_channel']} //Подключить канал
                onClick={() => setIsAddChannelPopupOpen(true)}
                show={!isAddChannelPopupOpen && !isPopupGroupOpen}
            />

            {/* Попап добавления канала */}
            <Popup
                open={isAddChannelPopupOpen}
                onClose={() => {
                    setIsAddChannelPopupOpen(false);
                    setAddChannelPopupInputText('');
                    setAddChannelErrorMessage('');
                }}
                title={locale['popup.add_channel.title']} // Добавить канал
                buttonText={locale['popup.btn.add']} // Добавить
                buttonView="outlined-info"
                onButtonClick={addChannel}
                buttonTwoText={locale['popup.btn.cancel']} // Отмена
                buttonTwoView="outlined-danger"
                isLoading={isAddChannelLoading}
                isError={!!addChannelErrorMessage}
            >
                <Text variant="caption-1">
                    {/* 1. Добавьте бот @botname в админы канала и предоставьте ему права на управление
                    постами. */}
                    {'1. ' + locale['popup.add_channel.text_1']}
                </Text>
                <Text variant="caption-1">
                    {/* 2. Введите короткий адрес или ID канала в поле ниже. */}
                    {'2. ' + locale['popup.add_channel.text_2']}
                </Text>
                <TextInput
                    size="l"
                    placeholder="@chanel"
                    autoFocus={true}
                    value={addChannelPopupInputText}
                    onChange={(e) => {
                        setAddChannelErrorMessage('');
                        setAddChannelPopupInputText(e.target.value);
                    }}
                    validationState={addChannelErrorMessage ? 'invalid' : undefined}
                    errorMessage={addChannelErrorMessage}
                />
            </Popup>

            {/* Попап добавления канала в группу */}
            <Popup
                open={isPopupGroupOpen}
                onClose={() => {
                    dispatch(setIsPopupGroupOpen(false));
                    dispatch(deleteSelectedChannels());
                    setAddChannelPopupInputText('');
                    setAddChannelErrorMessage('');
                }}
                title={locale['popup.add_to_group.title']} // Выбор группы каналов
                buttonText={channelGroups.length > 0 ? locale['popup.btn.add'] : ''} // Добавить
                buttonView="outlined-info"
                onButtonClick={addChannelsInGroup}
                buttonTwoText={locale['popup.btn.cancel']} //"Отмена"
                buttonTwoView="outlined"
                isLoading={isEditGroupLoading}
            >
                <Text variant="body-1">
                    {/* 'Добавить канал в группу:' | 'Нет созданных групп' */}
                    {channelGroups.length > 0
                        ? locale['popup.add_to_group.text']
                        : locale['popup.add_to_group.error.no_group']}
                </Text>
                {channelGroups.length > 0 && (
                    <Select
                        value={[addChannelInGroupSelectId]}
                        disabled={channelGroups.length === 0}
                        options={
                            channelGroups &&
                            channelGroups.map((channel: IChannel) => {
                                return {
                                    value: channel.id,
                                    content: channel.title,
                                };
                            })
                        }
                        onUpdate={(data: string[]) => {
                            setAddChannelInGroupSelectId(data[0]);
                        }}
                    />
                )}
            </Popup>
        </main>
    );
};
