import block from 'bem-cn-lite';
import './home.scss';

import ChannelsSelect from '../../ui/channelsSelect/channelsSelect';
import {RadioButton, RadioButtonOption, Spin, Text} from '@gravity-ui/uikit';
import TgButton from '../../ui/tgButton/tgButton';
import {screensSlice} from '../../../app/store/slices/screensSlice';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import BackButton from '../../ui/backButton/backButton';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {useEffect, useState} from 'react';
import {channelsSlice} from '../../../app/store/slices/channelsSlice';
import {PostList} from '../../ui/postList';
import {IChannel} from '../../../app/shared/types/channel.interface';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('home');

export const Home = () => {
    const {initData, user} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const {
        isSuccess: isInitSuccess,
        isLoading: isInitLoading,
        isError: isInitError,
    } = publisherApi.useInitQuery({
        initData,
    });
    const {channels} = useAppSelector((state) => state.postSlice);
    const {setScreen} = screensSlice.actions;
    const {deleteSelectedChannels} = channelsSlice.actions;
    const dispatch = useAppDispatch();

    const [radioButtonValue, setRadioButtonValue] = useState<'published' | 'delayed'>('published');

    const radioButtonOptions: RadioButtonOption[] = [
        {value: 'published', content: locale ? locale['wall.btn.published'] : ''}, // Опубликованые
        {value: 'delayed', content: locale ? locale['wall.btn.delayed'] : ''}, // Отложенные
    ];

    const paginationData = {
        delayed: radioButtonValue === 'delayed',
        index: 0,
        channels: channels.map((channel: IChannel) => {
            return {id: channel.id, type: channel.type};
        }),
    };

    const [getPosts, {data: postsData, isFetching: isPostDataLoading}] =
        publisherApi.useLazyGetPostsQuery();

    useEffect(() => {
        if (channels && channels.length > 0) {
            getPosts({initData, paginationData});
        }
    }, [channels, radioButtonValue]);

    useEffect(() => {
        return () => {
            deleteSelectedChannels();
        };
    }, []);

    return (
        <main className={b()}>
            <BackButton show={false} />
            {/* {!user && <button onClick={() => dispatch(setScreen('settings'))}>настройки</button>} */}
            {locale && <ChannelsSelect withButton />}
            {channels.length > 0 && (
                <RadioButton
                    name="posts"
                    size="l"
                    width="max"
                    defaultValue={radioButtonValue}
                    options={radioButtonOptions}
                    onChange={() =>
                        radioButtonValue === 'published'
                            ? setRadioButtonValue('delayed')
                            : setRadioButtonValue('published')
                    }
                />
            )}

            {postsData && channels.length > 0 && (
                <PostList
                    data={postsData.map((post: any) => {
                        return {
                            ...post,
                            textAbove: post.text_above,
                            disablePreview: post.disable_preview,
                        };
                    })}
                />
            )}

            {isInitLoading && <Spin className={b('spin')} size="xl" />}

            {isInitError && (
                <div className={b('message')}>
                    <div className={`${b('message-image')} ${b('message-image_error')}`}></div>
                    <Text variant="subheader-1" className={b('message-title')}>
                        {/* Пользователь не найден */}
                        {locale ? locale['wall.no_data_message.title'] : 'Пользователь не найден'}
                    </Text>
                    <Text variant="caption-2" className={b('message-text')}>
                        {/* Данные отсутствуют */}
                        {locale ? locale['wall.no_data_message.text'] : 'Данные отсутствуют'}
                    </Text>
                </div>
            )}

            {isInitSuccess && channels.length === 0 && locale && (
                <div className={b('message')}>
                    <div className={b('message-image')}></div>
                    <Text variant="subheader-1" className={b('message-title')}>
                        {/* Нет выбранных каналов */}
                        {locale['wall.no_channels_message.title']}
                    </Text>
                    <Text variant="caption-2" className={b('message-text')}>
                        {/* Для получения списка постов выберите хотя бы один канал или группу */}
                        {locale['wall.no_channels_message.text']}
                    </Text>
                </div>
            )}

            {locale && (
                <TgButton
                    title={locale['main_button.add_post']}
                    onClick={() => dispatch(setScreen('postEditor'))}
                    disabled={!isInitSuccess || isInitLoading}
                />
            )}
        </main>
    );
};
