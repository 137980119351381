import {PayloadAction, createSlice} from '@reduxjs/toolkit';

interface ISettingsSlice {
    theme: 'light' | 'dark-hc';
    language: 'ru' | 'en';
}

const initialState: ISettingsSlice = {
    theme: 'light',
    language: 'ru',
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<'light' | 'dark-hc'>) => {
            state.theme = action.payload;
        },
        setLanguage: (state, action: PayloadAction<'ru' | 'en'>) => {
            state.language = action.payload;
        },
    },
});

export default settingsSlice.reducer;
export const {setTheme} = settingsSlice.actions;
