import {Button, Icon, TextInput} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './searchInput.scss';
import {SearchInputProps} from './searchInput.types';
import {FC} from 'react';
import {Magnifier} from '@gravity-ui/icons';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('search-input');

const SearchInput: FC<SearchInputProps> = ({value, onChange, size = 'xl'}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    return (
        <TextInput
            value={value}
            onChange={onChange}
            placeholder={locale['search_input.placeholder']} // Поиск
            leftContent={<Icon data={Magnifier} size={16} className={b('icon')} />}
            size={size}
            className={b()}
        />
    );
};

export default SearchInput;
