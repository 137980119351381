import {Button, Card, Icon, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './textEditor.scss';
import {Xmark} from '@gravity-ui/icons';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {postSlice} from '../../../app/store/slices/postSlice';

import {FC, useEffect, useRef} from 'react';

import {EditorContent, useEditor} from '@tiptap/react';

import {useTelegram} from '../../../app/hooks/useTelegram';
import BubbleMenu from './bubbleMenu/bubbleMenu';

import {useTextEditorExtensions} from '../../../app/hooks/useTextEditorExtensions';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('text-editor');

interface PostTextProps {
    error?: string;
    setUnformattedText?: (unformattedText: string) => void;
}

const TextEditor: FC<PostTextProps> = ({error, setUnformattedText}) => {
    const {initData, platform} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const {text} = useAppSelector((store) => store.postSlice);
    const {setText} = postSlice.actions;
    const dispatch = useAppDispatch();

    const openUrButtonRef = useRef<HTMLButtonElement>(null);

    const {allExtensions} = useTextEditorExtensions('text-editor', locale);

    const editor = useEditor({
        extensions: allExtensions,
        content: text,

        editorProps: {
            attributes: {
                class: `${b('text-area')} ${error ? b('text-area_error') : ''}`,
            },
        },
        onUpdate({editor}) {
            dispatch(setText(editor.getHTML()));
            setUnformattedText && setUnformattedText(editor.getText());
        },
    });

    const handleButtonDelete = () => {
        editor?.commands.clearContent();
        dispatch(setText(''));
        setUnformattedText && setUnformattedText('');
    };

    useEffect(() => {
        setUnformattedText && editor && setUnformattedText(editor.getText());
    }, [editor]);

    return (
        <div className={b()}>
            <div className={b('menu')}>
                <Text as="h2" variant="subheader-1">
                    {/* Текст поста */}
                    {locale['editor.text_area.title']}
                </Text>

                <BubbleMenu editor={editor} platform={platform} openUrButtonRef={openUrButtonRef} />

                <Button
                    onClick={handleButtonDelete}
                    size="m"
                    view="flat"
                    className={b('close-button')}
                >
                    <Icon data={Xmark} size={16} />
                </Button>
            </div>

            <EditorContent editor={editor} />
            <div className={b('additional-content')}>
                <Text variant="body-1" color="hint" className={b('error-message')}>
                    {error ?? ''}
                </Text>
                <Text variant="caption-2" color="hint" className={b('counter')}>
                    {editor?.getText().length} / 4000
                </Text>
            </div>
        </div>
    );
};

export default TextEditor;
