import block from 'bem-cn-lite';
import './channelsGroup.scss';

import {Text, TextInput} from '@gravity-ui/uikit';
import {ChannelsList} from '../../ui/channelsList';
import {IChannel} from '../../../app/shared/types/channel.interface';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {FC, useEffect, useState} from 'react';
import TgButton from '../../ui/tgButton/tgButton';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {
    channelsSlice,
    deleteSelectedChannels,
    setSelectedChannels,
} from '../../../app/store/slices/channelsSlice';
import {screensSlice} from '../../../app/store/slices/screensSlice';
import BackButton from '../../ui/backButton/backButton';
import SearchInput from '../../ui/searchInput/searchInput';
import {group} from 'console';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('channels-group');

export const ChannelsGroup: FC = () => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const [title, setTitle] = useState('');
    const [searchInputValue, setSearchInputValue] = useState('');
    const {selectedChannels, channelButton, selectedGroup} = useAppSelector(
        (store) => store.channelsSlice,
    );
    const {data} = publisherApi.useInitQuery({initData});

    const [
        createGroupQuery,
        {
            isSuccess: isCreateGroupQuerySuccess,
            error: createGroupQueryError,
            data: createdGroupData,
            isLoading: isCreateGroupLoading,
        },
    ] = publisherApi.useAddGroupMutation();
    const [editGroupMutation, {isSuccess: isEditGroupSuccess, isLoading: isEditGroupLoading}] =
        publisherApi.useEditGroupMutation();
    const {setChannelButton, setSelectedGroup} = channelsSlice.actions;
    const {setScreen} = screensSlice.actions;
    const dispatch = useAppDispatch();
    const [createGroupErrorMessage, setCreateGroupErrorMessage] = useState('');

    const createGroup = () => {
        if (title) {
            const formData = new URLSearchParams();
            formData.append('title', title);
            if (selectedChannels.length) {
                selectedChannels.forEach((channelId: number) => {
                    formData.append('channels[]', channelId.toString());
                });
            }

            createGroupQuery({initData, formData});
        } else {
            // Введите название группы
            setCreateGroupErrorMessage(locale['channels_group.title_input.error']);
        }
    };

    const editGroup = () => {
        const formData = new URLSearchParams();
        formData.append('title', title);
        if (selectedChannels.length) {
            selectedChannels.forEach((channelId: number) => {
                formData.append('channels[]', channelId.toString());
            });
        } else {
            // formData.append('channels[]', []);
        }

        editGroupMutation({initData, groupId: selectedGroup?.id, formData});
    };

    const searchFilteredData = data
        ? data.channels.filter((channel: IChannel) =>
              channel.title?.toLowerCase().startsWith(searchInputValue.toLowerCase()),
          )
        : [];

    const channels = searchFilteredData
        ? searchFilteredData.filter((channel: IChannel) => channel.type === 'channel')
        : [];

    useEffect(() => {
        dispatch(setChannelButton('checkbox'));

        if (selectedGroup) {
            selectedGroup && selectedGroup.title && setTitle(selectedGroup.title);

            const ch = selectedGroup.channels?.map((channel: IChannel) => channel.id);
            ch && dispatch(setSelectedChannels(ch));
        }

        return () => {
            dispatch(setChannelButton('menu'));
            dispatch(deleteSelectedChannels());
        };
    }, []);

    useEffect(() => {
        isCreateGroupQuerySuccess && dispatch(setScreen('channels'));
    }, [isCreateGroupQuerySuccess]);

    useEffect(() => {
        isEditGroupSuccess && dispatch(setScreen('channels'));
    }, [isEditGroupSuccess]);

    useEffect(() => {
        createGroupQueryError && setCreateGroupErrorMessage(createGroupQueryError.toString());
    }, [createGroupQueryError]);

    return (
        <main className={b()}>
            <BackButton
                onClick={() => {
                    dispatch(setSelectedGroup(undefined));
                    dispatch(setScreen('channels'));
                }}
            />
            {/* {channelButton === 'checkbox' && <ChannelsTopMenu />} */}
            <div className={b('title')}>
                {/* Название группы */}
                <Text variant="subheader-1">{locale['channels_group.title']}</Text>
                <TextInput
                    placeholder={locale['channels_group.title_input.placeholder']} //Введите название
                    size="xl"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                        setCreateGroupErrorMessage('');
                    }}
                    validationState={createGroupErrorMessage ? 'invalid' : undefined}
                    errorMessage={createGroupErrorMessage}
                />
            </div>
            <div className={b('list-container')}>
                <div className={b('list-title-wrapper')}>
                    {/* Выбранные каналы */}
                    <Text variant="subheader-1">{locale['channels_group.channels']}</Text>
                    <Text variant="body-1" color="hint">
                        ({selectedChannels.length})
                    </Text>
                </div>
                {channels.length ? (
                    <SearchInput
                        value={searchInputValue}
                        onChange={(e) => setSearchInputValue(e.target.value)}
                    />
                ) : (
                    <Text className={b('message')} color="secondary">
                        {/* У Вас ещё нет подключенных телеграм-каналов */}
                        {locale['channels.message.no_channels']}
                    </Text>
                )}
            </div>

            {channels.length > 0 && <ChannelsList data={channels} />}
            <TgButton
                title={
                    selectedGroup ? locale['main_button.save'] : locale['main_button.create_group']
                } // 'Сохранить' | 'Создать группу'
                onClick={selectedGroup ? editGroup : createGroup}
                disabled={isCreateGroupLoading || !!createGroupErrorMessage || isEditGroupLoading}
            />
        </main>
    );
};
