import {Text, TextArea, TextInput} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './hiddenContinuationPopup.scss';
import {FC, useState} from 'react';
import {Popup} from '../popup';
import {HiddenContinuationPopupProps} from './hiddenContinuationPopup.types';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {postSlice} from '../../../app/store/slices/postSlice';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {yupSchemaHiddenContinuationPopupForm} from '../../../utils/validate';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';
import {useTelegram} from '../../../app/hooks/useTelegram';

const b = block('hidden-continuation-popup');

export const HiddenContinuationPopup: FC<HiddenContinuationPopupProps> = ({
    open,
    onClose,
    prevTitle = '',
    prevGuestText = '',
    prevSubscriberText = '',
    id,
}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const [title, setTitle] = useState(prevTitle);
    const [guestText, setGuestText] = useState(prevGuestText);
    const [subscriberText, setSubscriberText] = useState(prevSubscriberText);

    const {buttons} = useAppSelector((state) => state.postSlice);
    const {addButton, editButton} = postSlice.actions;
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm({
        mode: 'onSubmit',
        values: {title, guestText, subscriberText},
        resolver: yupResolver(yupSchemaHiddenContinuationPopupForm),
    });

    const handlePopupMainButton = () => {
        if (isValid) {
            if (id) {
                dispatch(
                    editButton({
                        id,
                        title,
                        guest: guestText,
                        subscriber: subscriberText,
                        type: 'alert',
                    }),
                );
            } else {
                dispatch(
                    addButton({
                        type: 'alert',
                        title,
                        subscriber: subscriberText,
                        guest: guestText,
                        id: buttons.length + 1,
                    }),
                );
            }

            onClose();
            !id && setTitle('');
            !id && setSubscriberText('');
            !id && setGuestText('');
        }
    };

    return (
        <Popup
            title={locale['popup.hidden_continuation.title']} //Добавить скрытое продолжение
            open={open}
            onClose={() => {
                onClose();
                setTitle(prevTitle || '');
                setGuestText(prevGuestText || '');
                setSubscriberText(prevSubscriberText || '');
            }}
            buttonText={id ? locale['popup.btn.save'] : locale['popup.btn.add']} // 'Сохранить' | 'Добавить'
            buttonView="outlined-info"
            onButtonClick={handleSubmit(handlePopupMainButton)}
            buttonTwoText={locale['popup.btn.cancel']} // Отмена
            buttonTwoView="outlined-danger"
            isError={
                !!errors.title?.message ||
                !!errors.guestText?.message ||
                !!errors.subscriberText?.message
            }
        >
            {/* Текст кнопки */}
            <Text variant="caption-2">{locale['popup.hidden_continuation.button_text']}</Text>
            <TextInput
                placeholder={locale['popup.hidden_continuation.button_text']}
                size="l"
                validationState={errors.title?.message ? 'invalid' : undefined}
                errorMessage={errors.title?.message}
                {...register('title', {
                    onChange(e) {
                        setTitle(e.target.value);
                    },
                    value: title,
                })}
            />

            {/* Текст НЕ для подписчиков */}
            <Text variant="caption-2">{locale['popup.hidden_continuation.guest']}</Text>
            <TextArea
                placeholder={locale['popup.hidden_continuation.guest']}
                size="l"
                minRows={3}
                validationState={errors.guestText?.message ? 'invalid' : undefined}
                errorMessage={errors.guestText?.message}
                {...register('guestText', {
                    onChange(e) {
                        setGuestText(e.target.value);
                    },
                    value: guestText,
                })}
            />
            <Text variant="caption-2" color="hint" className={b('counter')}>
                {`${guestText.length} / 4000`}
            </Text>

            {/* Текст для подписчиков */}
            <Text variant="caption-2">{locale['popup.hidden_continuation.subscriber']}</Text>
            <TextArea
                placeholder={locale['popup.hidden_continuation.subscriber']}
                size="l"
                minRows={3}
                validationState={errors.subscriberText?.message ? 'invalid' : undefined}
                errorMessage={errors.subscriberText?.message}
                {...register('subscriberText', {
                    onChange(e) {
                        setSubscriberText(e.target.value);
                    },
                    value: subscriberText,
                })}
            />
            <Text variant="caption-2" color="hint" className={b('counter')}>
                {`${subscriberText.length} / 4000`}
            </Text>
        </Popup>
    );
};
