import {Spin, ThemeProvider} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';

import './app.scss';

import {Home} from './components/screens/home';
import {PostEditor} from './components/screens/postEditor';
import {Channels} from './components/screens/channels';
import {useTelegram} from './app/hooks/useTelegram';
import {ChannelsGroup} from './components/screens/channelsGroup';
import {useAppDispatch, useAppSelector} from './app/hooks/redux';
import {useEffect} from 'react';
import {PostPreview} from './components/screens/postPreview';
import {IChannel} from './app/shared/types/channel.interface';
import {postSlice} from './app/store/slices/postSlice';
import {Settings} from './components/screens/settings/settings';
import {screensSlice} from './app/store/slices/screensSlice';
import {publisherApi} from './app/store/api/publisher/publisherApi';
import {settingsSlice} from './app/store/slices/settingsSlice';

const b = block('app');

const App = () => {
    const {initData, tg} = useTelegram();
    const {screen} = useAppSelector((store) => store.screensSlice);
    const {data: userData, isLoading: isUserDataLoading} = publisherApi.useInitQuery({initData});
    const {data: locale, isLoading: isLocalizationLoading} = publisherApi.useGetLocalizationQuery({
        initData,
    });
    const {setChannels} = postSlice.actions;
    const {setTheme, setLanguage} = settingsSlice.actions;
    const {setScreen, setPrevScreen} = screensSlice.actions;
    const {theme} = useAppSelector((store) => store.settingsSlice);
    const dispatch = useAppDispatch();

    const channelsData = userData && userData.channels ? userData.channels : [];

    useEffect(() => {
        tg.ready();
        tg.expand();
    }, []);

    // Сохраненные настройки пользователя
    useEffect(() => {
        userData && userData.settings.theme && dispatch(setTheme(userData.settings.theme));
        userData && userData.settings.language && dispatch(setLanguage(userData.settings.language));
    }, [userData]);

    // Кнопка настройки
    useEffect(() => {
        screen === 'settings' || !locale ? tg.SettingsButton.hide() : tg.SettingsButton.show();
        tg.SettingsButton.onClick(() => {
            dispatch(setPrevScreen(screen));
            dispatch(setScreen('settings'));
        });
    }, [screen, locale]);

    // Дефолтное значение селлекта каналов
    useEffect(() => {
        if (channelsData && channelsData.length) {
            const defaultSelectChannel: IChannel = channelsData[0];
            defaultSelectChannel && dispatch(setChannels([defaultSelectChannel]));
        }
    }, [channelsData]);

    return (
        <ThemeProvider theme={theme}>
            <div className={b()}>
                <div className={b('container')}>
                    {!isUserDataLoading && !isLocalizationLoading ? (
                        <>
                            {screen === 'home' && <Home />}
                            {screen === 'postEditor' && <PostEditor />}
                            {screen === 'channels' && <Channels />}
                            {screen === 'channelsGroup' && <ChannelsGroup />}
                            {screen === 'postPreview' && <PostPreview />}
                            {screen === 'settings' && <Settings />}
                        </>
                    ) : (
                        <div className={b('spin-wrapper')}>
                            <Spin size="xl" />
                        </div>
                    )}
                </div>
            </div>
        </ThemeProvider>
    );
};

export default App;
