import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {IChannel} from '../../shared/types/channel.interface';

interface IChannelsSlice {
    selectedChannels: number[];
    selectedGroup: IChannel | undefined;
    isAllChannelsSelected: boolean;
    channelButton: 'menu' | 'checkbox';
    isPopupGroupOpen: boolean;
}

const initialState: IChannelsSlice = {
    selectedChannels: [],
    selectedGroup: undefined,
    isAllChannelsSelected: false,
    channelButton: 'menu',
    isPopupGroupOpen: false,
};

export const channelsSlice = createSlice({
    name: 'channels',
    initialState,
    reducers: {
        setChannelButton: (state, action: PayloadAction<'menu' | 'checkbox'>) => {
            state.channelButton = action.payload;
        },
        setSelectedChannels: (state, action: PayloadAction<number[]>) => {
            state.selectedChannels = action.payload;
        },
        setSelectedGroup: (state, action: PayloadAction<IChannel | undefined>) => {
            state.selectedGroup = action.payload;
        },
        selectChannel: (state, action: PayloadAction<number>) => {
            state.selectedChannels = [...state.selectedChannels, action.payload];
        },

        unselectChannel: (state, action: PayloadAction<number>) => {
            state.selectedChannels = state.selectedChannels.filter((id) => id !== action.payload);
        },

        deleteSelectedChannels: (state) => {
            state.selectedChannels = [];
        },

        setIsAllChannelsSelected: (state, action: PayloadAction<boolean>) => {
            state.isAllChannelsSelected = action.payload;
        },

        setIsPopupGroupOpen: (state, action: PayloadAction<boolean>) => {
            state.isPopupGroupOpen = action.payload;
        },
    },
});

export default channelsSlice.reducer;
export const {
    setChannelButton,
    setSelectedChannels,
    setSelectedGroup,
    selectChannel,
    unselectChannel,
    deleteSelectedChannels,
    setIsAllChannelsSelected,
    setIsPopupGroupOpen,
} = channelsSlice.actions;
