import block from 'bem-cn-lite';
import './postList.scss';
import {FC} from 'react';
import {PostCard} from '../postCard';
import {PostListProps} from './postList.types';
import {IPost} from '../../../app/shared/types/post.interface';
import {Text} from '@gravity-ui/uikit';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('post-list');

export const PostList: FC<PostListProps> = ({data}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    return (
        <div className={b()}>
            {data.length > 0 ? (
                <div className={b('container')}>
                    {data.map((post: IPost, index: number) => (
                        <PostCard data={post} key={index} />
                    ))}
                </div>
            ) : (
                <Text className={b('message')}>{locale['post_list.no_posts']}</Text> // Нет постов
            )}
        </div>
    );
};
