import {Button, Icon, Select, SelectOption, Spin, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './postFile.scss';
import {File, Pencil, PlayFill, TrashBin} from '@gravity-ui/icons';
import {FC, useState} from 'react';
import {PostFileProps} from './postFile.types';
import {postSlice} from '../../../app/store/slices/postSlice';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import ModalWithMedia from '../modalWithMedia/modalWithMedia';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('post-file');

const PostFile: FC<PostFileProps> = ({
    url,
    type,
    name,
    size,
    asDocument,
    deletable,
    onEdit,
    onDelete,
    isLoading = false,
}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const {textAbove} = useAppSelector((store) => store.postSlice);
    const {setTextAbove} = postSlice.actions;
    const dispatch = useAppDispatch();
    const selectOptions: SelectOption[] = [
        {value: 'top', content: locale['post_file.select.top']},
        {value: 'bottom', content: locale['post_file.select.bottom']},
    ];

    const [isModalWithMediaOpen, setIsModalWithMediaOpen] = useState(false);

    return (
        <div className={b()}>
            {isLoading ? (
                <Spin size="xl" className={b('spin')} />
            ) : (
                <>
                    {type === 'image' && !asDocument && (
                        <img
                            src={url}
                            alt="post image"
                            className={b('media')}
                            onClick={() => setIsModalWithMediaOpen(true)}
                        />
                    )}
                    {type === 'video' && !asDocument && (
                        <div
                            className={b('media-wrapper')}
                            onClick={() => setIsModalWithMediaOpen(true)}
                        >
                            <Icon data={PlayFill} size={10} className={b('media-icon')} />
                            <video
                                playsInline={true}
                                muted={true}
                                autoPlay={true}
                                className={`${b('media')} ${b('media_video')}`}
                            >
                                <source src={url} />
                                {/* Формат не поддерживается */}
                                {locale['post_file.video.format_error']}
                            </video>
                        </div>
                    )}
                    {(type === 'document' || asDocument) && (
                        <div className={b('file')}>
                            {type !== 'image' && type !== 'video' && (
                                <div className={b('file-icon')}>
                                    <Icon data={File} size={20} />
                                </div>
                            )}
                            {type === 'image' && (
                                <img
                                    className={b('file-image')}
                                    src={url}
                                    alt={name}
                                    onClick={() => setIsModalWithMediaOpen(true)}
                                />
                            )}
                            {type === 'video' && (
                                <video
                                    playsInline={true}
                                    autoPlay={true}
                                    muted={true}
                                    className={b('file-image')}
                                    onClick={() => setIsModalWithMediaOpen(true)}
                                >
                                    <source src={url} />
                                    {/* Формат не поддерживается */}
                                    {locale['post_file.video.format_error']}
                                </video>
                            )}
                            <div className={b('file-text-wrapper')}>
                                <Text variant="subheader-1">
                                    {name.length > 26 ? name.slice(0, 26) + '...' : name}
                                </Text>
                                <Text variant="caption-2" color="hint">
                                    {(size / 1000).toFixed(2) + ` ${locale['post_file.size']}`}
                                </Text>
                            </div>
                        </div>
                    )}
                    <div className={b('button-container')}>
                        {type !== 'document' && !asDocument && (
                            <Select
                                size="l"
                                // label="Расположение"
                                width="max"
                                options={selectOptions}
                                defaultValue={textAbove ? ['bottom'] : ['top']}
                                className={b('select')}
                                onUpdate={(data) =>
                                    data[0] === 'top'
                                        ? dispatch(setTextAbove(false))
                                        : dispatch(setTextAbove(true))
                                }
                            />
                        )}
                        <Button onClick={onEdit} view="outlined" size="l">
                            <Icon data={Pencil} size={20} />
                        </Button>
                        {deletable && (
                            <Button onClick={onDelete} view="outlined-danger" size="l">
                                <Icon data={TrashBin} size={20} />
                            </Button>
                        )}
                    </div>
                </>
            )}

            <ModalWithMedia
                isOpen={isModalWithMediaOpen}
                onClose={() => setIsModalWithMediaOpen(false)}
                url={url}
                type={type}
                alt={name}
            />
        </div>
    );
};

export default PostFile;
