import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import channelsSlice from './slices/channelsSlice';
import screensSlice from './slices/screensSlice';
import postSlice from './slices/postSlice';
import settingsSlice from './slices/settingsSlice';
import {publisherApi} from './api/publisher/publisherApi';

const rootReducer = combineReducers({
    channelsSlice,
    screensSlice,
    postSlice,
    settingsSlice,
    [publisherApi.reducerPath]: publisherApi.reducer,
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(publisherApi.middleware),
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
