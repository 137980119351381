import {PayloadAction, createSlice} from '@reduxjs/toolkit';

interface IScreenSlice {
    screen: 'home' | 'channels' | 'channelsGroup' | 'postEditor' | 'postPreview' | 'settings';
    prevScreen?: 'home' | 'channels' | 'channelsGroup' | 'postEditor' | 'postPreview' | 'settings';
}

const initialState: IScreenSlice = {
    screen: 'home',
    prevScreen: undefined,
};

export const screensSlice = createSlice({
    name: 'screens',
    initialState,
    reducers: {
        setScreen: (
            state,
            action: PayloadAction<
                'home' | 'channels' | 'channelsGroup' | 'postEditor' | 'postPreview' | 'settings'
            >,
        ) => {
            state.screen = action.payload;
        },

        setPrevScreen: (
            state,
            action: PayloadAction<
                'home' | 'channels' | 'channelsGroup' | 'postEditor' | 'postPreview' | 'settings'
            >,
        ) => {
            state.prevScreen = action.payload;
        },
    },
});

export default screensSlice.reducer;
export const {setScreen, setPrevScreen} = screensSlice.actions;
