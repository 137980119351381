import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import TextExtension from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Blockquote from '@tiptap/extension-blockquote';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';

import block from 'bem-cn-lite';
import {Extension} from '@tiptap/core';

export const useTextEditorExtensions = (blockName: string, locale: any) => {
    const b = block(blockName);

    const OverrideEscape = Extension.create({
        name: 'OverrideEscape',
        addKeyboardShortcuts() {
            return {
                Escape: () => this.editor.commands.blur(),
            };
        },
    });

    const CustomStrike = Strike.extend({
        addKeyboardShortcuts() {
            return {
                'Mod-Shift-x': () => this.editor.commands.toggleStrike(),
            };
        },
    });

    const CustomBlockquote = Blockquote.extend({
        addKeyboardShortcuts() {
            return {
                'Mod-Shift-i': () => this.editor.commands.toggleBlockquote(),
            };
        },
    });

    const CustomUnderline = Underline.extend({
        addKeyboardShortcuts() {
            return {
                'Mod-Shift-u': () => this.editor.commands.toggleUnderline(),
            };
        },
    });

    const OverrideModU = Extension.create({
        name: 'OverrideModU',
        addKeyboardShortcuts() {
            return {
                'Mod-u': () => this.editor.commands.keyboardShortcut(''),
            };
        },
    });

    const CustomBold = Bold.extend({
        addKeyboardShortcuts() {
            return {
                'Mod-b': () => this.editor.commands.toggleBold(),
            };
        },
    });

    const CustomItalic = Italic.extend({
        addKeyboardShortcuts() {
            return {
                'Mod-i': () => this.editor.commands.toggleItalic(),
            };
        },
    });

    const CustomLink = Link.extend({
        addKeyboardShortcuts() {
            return {
                'Mod-u': () => {
                    return this.editor.commands.selectNodeForward();
                },
            };
        },
    });

    const CustomHighlight = Highlight.extend({
        addKeyboardShortcuts() {
            return {
                'Mod-Shift-p': () => {
                    return this.editor.commands.toggleHighlight({color: '#cbcbcb48'});
                },
            };
        },
    });

    const allExtensions = [
        Document,
        TextExtension,
        Paragraph,
        CustomBlockquote.configure({
            HTMLAttributes: {
                class: b('blockquote'),
            },
        }),
        Placeholder.configure({
            placeholder: locale['editor.text_area.placeholder'],
            emptyEditorClass: 'is-editor-empty',
        }),
        CustomHighlight.configure({
            multicolor: true,
            HTMLAttributes: {
                class: b('highlight'),
                'data-tg': 'hidden',
            },
        }),
        CustomLink.configure({
            openOnClick: false,
            autolink: true,
            HTMLAttributes: {
                class: b('link'),
                target: '_blank',
            },
        }),
        CustomStrike,
        CustomUnderline,
        CustomBold,
        CustomItalic,
        OverrideEscape,
        OverrideModU,
    ];

    return {allExtensions};
};
