import block from 'bem-cn-lite';
import './settings.scss';

import {FC, useEffect, useRef, useState} from 'react';
import {Button, Card, Icon, Popup, Spin, Switch, Text} from '@gravity-ui/uikit';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {ChevronDown, Globe, Moon} from '@gravity-ui/icons';
import BackButton from '../../ui/backButton/backButton';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {screensSlice} from '../../../app/store/slices/screensSlice';
import TgButton from '../../ui/tgButton/tgButton';
import {settingsSlice} from '../../../app/store/slices/settingsSlice';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('settings');

export const Settings: FC = () => {
    const {initData, user} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({
        initData,
    });
    const [fetchUpdateSettings] = publisherApi.useUpdateSettingsMutation();
    const {setScreen} = screensSlice.actions;
    const {setTheme, setLanguage} = settingsSlice.actions;
    const {prevScreen} = useAppSelector((store) => store.screensSlice);
    const {theme, language} = useAppSelector((store) => store.settingsSlice);
    const dispatch = useAppDispatch();

    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
    const boxRef = useRef(null);

    return (
        <main className={b()}>
            <BackButton onClick={() => dispatch(setScreen(prevScreen ? prevScreen : 'home'))} />

            {/* НАСТРОЙКИ */}
            <Text variant="subheader-3">{locale['settings.title']}</Text>
            <div className={b('user')}>
                <div className={b('user-avatar')}>
                    {!user && <Text variant="header-1">UN</Text>}
                    {user && user.first_name && (
                        <Text variant="header-1">{user.first_name[0].toUpperCase()}</Text>
                    )}
                    {user && user.last_name && (
                        <Text variant="header-1">{user.last_name[0].toUpperCase()}</Text>
                    )}
                </div>
                <div className={b('user-wrapper')}>
                    <Text variant="subheader-2">
                        {user ? `${user.first_name} ${user.last_name}` : 'User Name'}
                    </Text>
                    <Text variant="caption-2" color="hint">
                        {user ? `@${user.username}` : '@username'}
                    </Text>
                </div>
            </div>

            <Card view="filled" className={b('settings-container')}>
                {/* Язык */}
                <div className={b('settings-item')}>
                    <Icon size={16} data={Globe} />
                    <Text variant="body-1">{locale['settings.lang']}</Text>
                    <Button
                        onClick={() => setIsLanguageMenuOpen(true)}
                        ref={boxRef}
                        view="flat"
                        className={b('button-lang')}
                    >
                        <div className={b('button-child')}>
                            <Text color="info">
                                {language === 'ru'
                                    ? locale['settings.lang.ru']
                                    : locale['settings.lang.en']}
                            </Text>
                            <Icon data={ChevronDown} />
                        </div>
                    </Button>
                </div>

                {/* Ночной режим */}
                <div className={b('settings-item')}>
                    <Icon size={16} data={Moon} />
                    <Text variant="body-1">{locale['settings.dark_theme']}</Text>
                    <Switch
                        checked={theme === 'dark-hc'}
                        onChange={() => {
                            if (theme === 'dark-hc') {
                                dispatch(setTheme('light'));
                                fetchUpdateSettings({
                                    initData,
                                    settings: {language: language, theme: 'light'},
                                });
                            } else {
                                dispatch(setTheme('dark-hc'));
                                fetchUpdateSettings({
                                    initData,
                                    settings: {language: language, theme: 'dark-hc'},
                                });
                            }
                        }}
                        size="l"
                        className={b('switch')}
                    />
                </div>
            </Card>

            {/* Сохранить */}
            <TgButton title={locale['main_button.save']} show={false} />

            <Popup
                open={isLanguageMenuOpen}
                anchorRef={boxRef}
                placement="left-end"
                offset={[50, -100]}
                onOutsideClick={() => setIsLanguageMenuOpen(false)}
                className={b('popup')}
            >
                {/* RU */}
                <Button
                    onClick={() => {
                        if (language !== 'ru') {
                            dispatch(setLanguage('ru'));
                            fetchUpdateSettings({
                                initData,
                                settings: {language: 'ru', theme},
                            });
                            dispatch(publisherApi.util.invalidateTags(['LOCALIZATION']));
                        }
                        setIsLanguageMenuOpen(false);
                    }}
                    size="l"
                    view="flat"
                    className={b('button')}
                >
                    <div className={b('button-child')}>{locale['settings.lang.ru']}</div>
                </Button>

                {/* EN */}
                <Button
                    onClick={() => {
                        if (language !== 'en') {
                            dispatch(setLanguage('en'));
                            fetchUpdateSettings({
                                initData,
                                settings: {language: 'en', theme},
                            });
                            dispatch(publisherApi.util.invalidateTags(['LOCALIZATION']));
                        }
                        setIsLanguageMenuOpen(false);
                    }}
                    size="l"
                    view="flat"
                    className={b('button')}
                >
                    <div className={b('button-child')}>{locale['settings.lang.en']}</div>
                </Button>
            </Popup>
        </main>
    );
};
