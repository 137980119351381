import block from 'bem-cn-lite';
import './mobileDatePicker.scss';
import DatePicker from 'react-mobile-datepicker';
import {FC, useEffect, useState} from 'react';
import moment from 'moment';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('mobile-date-picker');

interface MobileDatePickerProps {
    isOpen: boolean;
    onSelect: (date: any) => void;
    onCancel: () => void;
    delay: number | null;
}

export const MobileDatePicker: FC<MobileDatePickerProps> = ({
    isOpen,
    onSelect,
    onCancel,
    delay,
}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const monthMap = {
        '1': 'Января',
        '2': 'Февраля',
        '3': 'Марта',
        '4': 'Апреля',
        '5': 'Мая',
        '6': 'Июня',
        '7': 'Июля',
        '8': 'Августа',
        '9': 'Сентября',
        '10': 'Октября',
        '11': 'Ноября',
        '12': 'Декабря',
    };

    const dateNow = new Date(moment().format().slice(0, 16));
    const [value, setValue] = useState(dateNow);

    useEffect(() => {
        if (delay) {
            const delayString = new Date(delay * 1000);
            setValue(delayString);
        } else {
            setValue(dateNow);
        }

        document.body.style.overflow = isOpen ? 'hidden' : '';
    }, [isOpen]);

    const dateConfig = {
        date: {
            format: (value: Date) =>
                dateNow.getDate() === value.getDate() && dateNow.getMonth() === value.getMonth()
                    ? locale['datepicker.today'] // Сегодня
                    : // @ts-ignore
                      `${value.getDate()} ${monthMap[value.getMonth() + 1]}, ${value.getFullYear()}`,
            caption: 'Day',
            step: 1,
        },
        hour: {
            format: 'hh',
            caption: 'Hour',
            step: 1,
        },
        minute: {
            format: 'mm',
            caption: 'Min',
            step: 1,
        },
    };
    return (
        <div className={b()}>
            <DatePicker
                isOpen={isOpen}
                onCancel={onCancel}
                dateConfig={dateConfig}
                confirmText={locale['datepicker.btn.ok']} //
                cancelText={locale['datepicker.btn.cancel']} // Отмена
                theme="ios"
                showHeader={false}
                onSelect={(date: any) => {
                    onCancel();
                    onSelect(Date.parse(date) / 1000);
                }}
                value={value}
                onChange={(date: any) => setValue(date)}
                min={dateNow}
            />
        </div>
    );
};
