import {Button} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './tgButton.scss';
import {TgButtonProps} from './tgButton.types';
import {FC, useEffect} from 'react';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {useAppSelector} from '../../../app/hooks/redux';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('tg-button');

const TgButton: FC<TgButtonProps> = ({title, onClick, show = true, disabled = false}) => {
    const {user, tg, initData} = useTelegram();
    const {isSuccess} = publisherApi.useInitQuery({initData});

    useEffect(() => {
        if (user) {
            tg.MainButton.setParams({color: '#007aff'});
            tg.MainButton.show();
            tg.MainButton.enable();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (user) {
            show ? tg.MainButton.show() : tg.MainButton.hide();
        }
    }, [show]);

    useEffect(() => {
        if (user) {
            disabled ? tg.MainButton.disable() : tg.MainButton.enable();
            disabled
                ? tg.MainButton.setParams({color: '#A6D0FF'})
                : tg.MainButton.setParams({color: '#007aff'});
        }
    }, [disabled]);

    useEffect(() => {
        user && tg.MainButton.setText(title);
        user && tg.MainButton.onClick(onClick);

        return () => {
            user && tg.MainButton.offClick(onClick);
        };
    }, [title, onClick]);
    return (
        <>
            {!user && (
                <div className={b()}>
                    <Button
                        onClick={onClick}
                        view="action"
                        size="xl"
                        width="max"
                        disabled={disabled}
                        className={b('button')}
                    >
                        {title}
                    </Button>
                </div>
            )}
        </>
    );
};

export default TgButton;
