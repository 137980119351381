import {Icon, RadioButton, RadioButtonOption, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './postButton.scss';
import {FC, useState} from 'react';
import {ArrowRight} from '@gravity-ui/icons';
import {PostButtonProps} from './postButton.types';
import {Popup} from '../popup';
import {useAppSelector} from '../../../app/hooks/redux';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('post-button');

export const PostButton: FC<PostButtonProps> = ({data}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const {theme} = useAppSelector((store) => store.settingsSlice);

    const handleLinkButton = () => {
        window.open(data.url, '_blank');
        setIsPopupOpen(false);
    };

    const [radioButtonValue, setRadioButtonValue] = useState<'guest' | 'subscriber'>('guest');

    const radioButtonOptions: RadioButtonOption[] = [
        {value: 'guest', content: locale['popup.hidden_continuation.select.guest']}, // Нет подписки
        {value: 'subscriber', content: locale['popup.hidden_continuation.select.subscriber']}, // Подписка
    ];

    return (
        <>
            <div
                className={`${b()} ${b('background')} ${theme === 'light' ? b('background_light') : b('background_dark')}`}
                onClick={() => (data.type === 'link' ? handleLinkButton() : setIsPopupOpen(true))}
            >
                <Text color="light-primary" className={b('title')}>
                    {data.title}
                </Text>
                {data.type === 'link' && <Icon data={ArrowRight} size={10} className={b('icon')} />}
            </div>
            <Popup
                open={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                title={locale['popup.hidden_continuation.title']} // Скрытое продолжение
                buttonTwoText={locale['popup.btn.close']} // Закрыть
                buttonTwoView="outlined"
            >
                <>
                    <RadioButton
                        name="posts"
                        size="m"
                        width="max"
                        defaultValue={radioButtonValue}
                        options={radioButtonOptions}
                        onChange={() =>
                            radioButtonValue === 'guest'
                                ? setRadioButtonValue('subscriber')
                                : setRadioButtonValue('guest')
                        }
                    />
                    <Text variant="caption-2">
                        {radioButtonValue === 'guest' ? data.guest : data.subscriber}
                    </Text>
                </>
            </Popup>
        </>
    );
};
