import {Button, Icon, Select, SelectOptionGroup, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './channelsSelect.scss';
import {Gear} from '@gravity-ui/icons';
import {screensSlice} from '../../../app/store/slices/screensSlice';
import {useAppDispatch, useAppSelector} from '../../../app/hooks/redux';
import {useTelegram} from '../../../app/hooks/useTelegram';
import {IChannel} from '../../../app/shared/types/channel.interface';
import {FC, useEffect} from 'react';
import {postSlice} from '../../../app/store/slices/postSlice';
import {publisherApi} from '../../../app/store/api/publisher/publisherApi';

const b = block('channel-select');

interface ChannelsSelectProps {
    withButton?: boolean;
    error?: string;
    disabled?: boolean;
    // register?: any;
}

const ChannelsSelect: FC<ChannelsSelectProps> = ({withButton = false, error, disabled = false}) => {
    const {initData} = useTelegram();
    const {data: locale} = publisherApi.useGetLocalizationQuery({initData});
    const {screen} = useAppSelector((state) => state.screensSlice);
    const {channels} = useAppSelector((state) => state.postSlice);
    const {setScreen} = screensSlice.actions;
    const {setChannels} = postSlice.actions;
    const dispatch = useAppDispatch();

    const {data: userInitData, isSuccess} = publisherApi.useInitQuery({initData});

    const options: SelectOptionGroup[] =
        userInitData.channels &&
        userInitData.channels.map((channel: IChannel) => {
            return {
                value: channel.id,
                // data: {height: 35},
                content: channel.type === 'channel' ? channel.title : channel.title + ' (группа)',
            };
        });

    const handleChannelsSelectChange = (channels: any[]) => {
        const selectedChannels = userInitData.channels.filter((channel: IChannel) => {
            return channels.includes(channel.id);
        });

        const result = selectedChannels.map((channel: IChannel) => {
            return {id: channel.id, type: channel.type, title: channel.title};
        });

        dispatch(setChannels(result));
    };

    const selectValue = channels.map((channel: IChannel) => channel.id);

    return (
        <div className={b()}>
            <Text as="h2" variant="subheader-1">
                {locale['channel_select.title']}
            </Text>
            <div className={b('wrapper')}>
                <Select
                    placeholder={
                        userInitData.channels && userInitData.channels.length > 0
                            ? locale['channel_select.placeholder.select']
                            : locale['channel_select.placeholder.no_channels']
                    }
                    disabled={
                        !userInitData.channels || userInitData.channels.length === 0 || disabled
                    }
                    size="xl"
                    pin="round-round"
                    // width="max"
                    multiple
                    hasClear
                    // @ts-ignore
                    value={[...selectValue]}
                    options={options}
                    onUpdate={handleChannelsSelectChange}
                    className={`${b('select')} ${withButton ? b('select_with-button') : ''}`}
                    validationState={error ? 'invalid' : undefined}
                    errorMessage={error}
                />
                {screen !== 'postEditor' && (
                    <Button
                        disabled={!isSuccess}
                        onClick={() => dispatch(setScreen('channels'))}
                        view="action"
                        size="xl"
                    >
                        <Icon data={Gear} size={20} />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ChannelsSelect;
