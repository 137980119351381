import {Button, Card, Icon, Switch, Text} from '@gravity-ui/uikit';

import block from 'bem-cn-lite';
import './postButtonPreview.scss';
import {ArrowRight, Grip, Pencil, TrashBin} from '@gravity-ui/icons';
import {FC, useState} from 'react';
import {postSlice} from '../../../app/store/slices/postSlice';
import {useAppDispatch} from '../../../app/hooks/redux';
import {LinkButtonPopup} from '../linkButtonPopup';
import {HiddenContinuationPopup} from '../hiddenContinuationPopup';
import {IPostButton} from '../../../app/shared/types/postButton.interface';

const b = block('post-button-preview');

const PostButtonPreview: FC<IPostButton> = ({title, url, type, id, subscriber, guest}) => {
    const [isEditButtonPopupOpen, setIsEditButtonPopupOpen] = useState(false);
    const {deleteButton} = postSlice.actions;
    const dispatch = useAppDispatch();
    return (
        <div className={b()}>
            <Icon data={Grip} size={20} className={b('icon')} />
            <Card view="filled" className={b('card')}>
                <Text as="p" className={b('card-title')}>
                    {title}
                </Text>
                {type === 'link' && <Icon data={ArrowRight} size={15} className={b('card-icon')} />}
            </Card>
            <div className={b('button-container')}>
                <Button onClick={() => setIsEditButtonPopupOpen(true)} view="outlined" size="l">
                    <Icon data={Pencil} size={20} />
                </Button>
                <Button onClick={() => dispatch(deleteButton(id))} view="outlined-danger" size="l">
                    <Icon data={TrashBin} size={20} />
                </Button>
            </div>
            <LinkButtonPopup
                open={type === 'link' && isEditButtonPopupOpen}
                onClose={() => setIsEditButtonPopupOpen(false)}
                prevTitle={title}
                prevUrl={url}
                id={id}
            />
            <HiddenContinuationPopup
                open={type === 'alert' && isEditButtonPopupOpen}
                onClose={() => setIsEditButtonPopupOpen(false)}
                prevTitle={title}
                prevGuestText={guest}
                prevSubscriberText={subscriber}
                id={id}
            />
        </div>
    );
};

export default PostButtonPreview;
